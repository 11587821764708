import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { Grid, Row, Col } from 'react-flexbox-grid'
import styled from '@emotion/styled'
import chunk from 'lodash/chunk'

import ResponsiveTitle from '../components/ResponsiveTitle'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { DESKTOP_MIN_BREAKPOINT, TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'
import { useIntl } from 'gatsby-plugin-intl'

const TitleWrapper = styled.div`
  padding-bottom: 2rem;
`

const CategorySection = styled.div`
  display: flex;
  justify-content: center;
  min-width: 12rem;
  padding: 0.5rem 0;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 2rem;
  line-height: 2rem;
  background-color: rgba(255, 255, 255, 0.7);

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    height: 3rem;
    line-height: 3rem;
  }
  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    height: 4rem;
    line-height: 4rem;
  }
`

const ImageOverlayH3 = styled.h3`
  color: black;
  text-align: center;
`

export const PortfolioPageTemplate = ({ title, categories }) => {
  const [chunkLeft, chunkRight] = chunk(categories, 2)
  const { formatMessage } = useIntl()

  const renderCategoryThumbnails = categoriesChunk =>
    categoriesChunk.map(category => (
      <Col key={category.key} xs>
        <CategorySection>
          <ImageWrapper>
            <Link to={`/portfolio/${category.key}`}>
              <PreviewCompatibleImage imageInfo={category.image} />
              <ImageOverlay>
                <ImageOverlayH3 className="is-size-3-tablet is-size-2-widescreen">
                  {formatMessage({ id: `portfolio.${category.key}` })}
                </ImageOverlayH3>
              </ImageOverlay>
            </Link>
          </ImageWrapper>
        </CategorySection>
      </Col>
    ))

  return (
    <Grid fluid style={{ width: '100%' }}>
      <Row>
        <Col xs={12}>
          <TitleWrapper>
            <ResponsiveTitle title={formatMessage({ id: 'portfolio.title' })} />
          </TitleWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          {renderCategoryThumbnails(chunkLeft)}
        </Col>
        <Col xs={12} sm={6}>
          {renderCategoryThumbnails(chunkRight)}
        </Col>
      </Row>
    </Grid>
  )
}

PortfolioPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
}

const PortfolioPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data

  return (
    <Layout>
      <PortfolioPageTemplate title={frontmatter.title} categories={frontmatter.categories} />
    </Layout>
  )
}

PortfolioPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PortfolioPage

export const portfolioPageQuery = graphql`
  query PortfolioPage {
    markdownRemark(frontmatter: { templateKey: { eq: "portfolio-page" } }) {
      frontmatter {
        title
        categories {
          key
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
